<script lang="ts" setup>
const benefits = ref<{ text: string; icon: string }[]>([
  {
    text: 'Your daily challenge',
    icon: 'benefits/play'
  },
  {
    text: 'Win maximum tricks for a golden crown',
    icon: 'achievements/max-tricks'
  },
  {
    text: 'Track your progress, up your rating',
    icon: 'benefits/fire'
  },
  {
    text: 'Catch up on missed games',
    icon: 'benefits/cards'
  }
]);
</script>

<template>
  <div class="relative flex w-full min-h-36 p-5 bg-white rounded-[5px]">
    <ul class="grid gap-2">
      <li
        v-for="(benefit, index) in benefits"
        :key="index"
        class="benefit-item"
      >
        <NuxtIcon :name="benefit.icon" filled class="mr-2" />
        <span class="text-black text-base leading-5">{{ benefit.text }}</span>
      </li>
    </ul>
    <NuxtImg
      src="/images/cards-2.png"
      alt="Cards"
      class="hidden md:block w-auto h-full absolute bottom-0 right-0"
    />
    <!--    <NuxtImg-->
    <!--      src="/images/cards-2-mobile.png"-->
    <!--      alt="Cards"-->
    <!--      class="md:hidden w-auto h-full absolute bottom-0 right-0"-->
    <!--    />-->
  </div>
</template>
<style lang="scss" scoped>
.benefit-item {
  @apply inline-flex items-center;

  :deep(.nuxt-icon svg) {
    @apply h-5 w-5 mb-0;
  }
}
</style>
